import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/SEO";

function SparPengePaaGulvet() {
  return (
    <>
      <SEO
        title="Find de bedste priser på fitnessgulv til hjemmefitness"
        description="Vi har fundet nogle af de bedste bud på, hvor du kan finde det bedste fitnessgulv til den bedste pris. Fitnessgulv er et must til dit hjemmefitness, som noget af det allerførste."
      />
      <article className="flex flex-col items-center mx-auto max-w-4xl my-12">
        <h1 className="font-bold text-4xl mb-4">Spar penge på fitnessgulvet</h1>
        <p className="mb-4 text-2xl leading-10">
          Står du og skal til at indrette et træningslokale? Så ved vi, at
          fitnessgulv, er noget af det første du skal på udkig efter. Både for
          selve gulvet, uanset underlaget, vil fitnessgulv være uundværligt. Det
          hjælper samtidig på vedligehold af vægtene der stilles herpå.
        </p>
        <p className="mb-4 text-2xl leading-10">
          Vi har her i artiklen samlet de bedste bud, på fitnessgulv af højeste
          kvalitet - som endda er til at betale.
        </p>
        <h2 className="font-bold text-3xl my-4">Floor Guards</h2>
        <p className="mb-4 text-2xl leading-10">
          Gør det nemt og køb en samlet pakke af plader, som dækker 5,76 kvm.
          Pladerne dækker 1x1 meter og består af 16 plader.
          <div className="flex my-4 justify-center">
            <Link to="/udstyr/nordic-fighter-gummi-floor-guards-fitnessgulv-all-black-10mm" className="btn green mr-2">
              Læs mere
            </Link>
          </div>
        </p>
        <h2 className="font-bold text-3xl my-4">ErgoFloor</h2>
        <p className="mb-4 text-2xl leading-10">
          Med en tykkelse på 1cm, er du sikret vedligehold af de vægtskiver og
          håndvægte du stiller. Pladerne består af 1 meter x 1 meter.
          <div className="flex my-4 justify-center">
            <Link to="/udstyr/ergofloor-ergoplay-impact-gummifliser--faldunderlag-500x500x90-mm-sort" className="btn green mr-2">
              Læs mere
            </Link>
          </div>
        </p>
        <h2 className="font-bold text-3xl my-4">Reebok Floor Guards</h2>
        <p className="mb-4 text-2xl leading-10">
          Her sparer du penge. For en lav pris, får du et underlag der dækker
          60x60cm for 4 stk. Anbefales til underlag for løbebåndet,
          motionscyklen eller lettere håndvægte.
          <div className="flex my-4 justify-center">
            <Link to="/udstyr/reebok-floor-guards-fitnessgulv-4-stk" className="btn green mr-2">
              Læs mere
            </Link>
          </div>
        </p>
      </article>
    </>
  );
}

export default SparPengePaaGulvet;
